import React from "react";
import '../css/explorecards.css';

const ExploreCards = () => {

    return (
        <div class="row row-cols-2 row-cols-sm-2 g-4 mt-3 explore-cards-row">
        <div class="col">
            <div class="card h-100 EC-card">
            {/* <img src="..." class="card-img-top" alt="..."/> */}
            <div class="card-body">
                <h5 class="card-title ct">Trending</h5>
                {/* <i class="fa-solid fa-arrow-right-long"></i> */}
                {/* <p class="card-text">Explore the hottest venues and locations where everyone is heading. Stay in the loop with the places that are currently buzzing with activity and excitement.</p> */}
            </div>
            </div>
        </div>
        <div class="col">
            <div class="card h-100 EC-card">
            {/* <img src="..." class="card-img-top" alt="..."/> */}
            <div class="card-body">
                <h5 class="card-title ct">Organizers</h5>
                {/* <i class="fa-solid fa-arrow-right-long"></i> */}
                {/* <p class="card-text">Stay ahead of the game by browsing through a curated list of upcoming events. Never miss out on what’s happening next in your city with our comprehensive event schedule.</p> */}
            </div>
            </div>
        </div>
        </div>
    )
}

export default ExploreCards;