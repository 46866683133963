// import logo from './logo.svg';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import './App.css';
// import Login from './components/login';
// import Register from './components/register';
// import Home from './components/home';
// import Calendar from './components/calendar';
// import Header from './components/header';
// import Nav from './components/nav';
// import { useState } from 'react';

// function App() {

//   const [state, setState] = useState({});

//   const addToState = (obj) => {
//     let info = JSON.parse(obj);
//     setState(info);
//     console.log(state);
//   }


//   return (
//     <div className="App">
//           <Router>
//             <Routes>
//               <Route path="/" element={<Login addToState={addToState}/>} />
//       <Header/>
//               <Route path="/register" element={<Register/>} />
//               <Route path="/home" element={<Home/>} />
//               <Route path="/calendar" element={<Calendar/>} />
//               <Route path="*" element={<Navigate to="/" />} />
//       <Nav/>
//             </Routes>
//           </Router>
//     </div>
//   );
// }

// export default App;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Login from './components/login';
import Register from './components/register';
import Home from './components/home';
import Calendar from './components/calendar';
import Header from './components/header';
import Nav from './components/nav';
import AddEvent from './components/addevent';
import UserProfile from './components/userprofile';

function App() {
  const [state, setState] = useState({});

  const addToState = (array) => {
    let info = JSON.parse(array);
    setState(info[0]);
  }

  const Layout = ({ children }) => {
    const location = useLocation();
    const showHeaderAndNav = location.pathname !== '/';

    return (
      <>
        {showHeaderAndNav && <Header username={state.username}/>}
        {children}
        {showHeaderAndNav && <Nav/>}
      </>
    );
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login addToState={addToState} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Layout><Home id={state.id} username={state.username}/></Layout>} />
          <Route path="/calendar" element={<Layout><Calendar id={state.id} /></Layout>} />
          <Route path="/addevent" element={<Layout><AddEvent id={state.id} /></Layout>} />
          <Route path="/my-profile" element={<Layout><UserProfile id={state.id} username={state.username}/></Layout>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
