import React, { useEffect, useState } from "react";
import Header from "./header";
import '../css/home.css';
import ExploreCards from "./explorecards";
import { useLocation, useNavigate } from "react-router-dom";
import Nav from "./nav";
import functions from "../fn/function";
import TicketmasterEvents from "./tmevenetsrap";
import MyEvents from "./myevents";
import EventList from "./eventlist";
import Top5TrendingEvents from "./top5";

const Home = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const id = props.id;
    const username = props.username;
    // const parsedData = JSON.parse(data);
    const [rapEvents, setRapEvents] = useState([]);

    useEffect(() => {
        if(id === undefined || id === null){
            navigate('/');
        } else {
            getEvents();
        }
    }, []);

    const getEvents = async () => {
        const response = await functions.getEvents();
        setRapEvents(response);
        return response;
    }
    
    return (
        <div className="home-container container p-0">
            <div className="container-fluid home-content-subcontainer">

                {/* cards for different events */}
                <div className="row">
                    <div className="col-12">
                        <ExploreCards/>
                    </div>
                </div>

                {/* rap events in new Orleans */}
                <div className="row">
                    <div className="col-12 p-0">
                        <TicketmasterEvents rapevents={rapEvents} getevents={getEvents} id={id} name={username}/>
                    </div>
                </div>

                {/* random 6 events*/}
                <div className="row">
                    <div className="col-12 p-0">
                        <EventList/>
                    </div>
                </div>

                {/* events created by user */}
                <div className="row">
                    <div className="col-12 p-0">
                        <MyEvents id={id}/>
                    </div>
                </div>

                {/* random 6 events*/}
                <div className="row">
                    <div className="col-12 p-0">
                        <Top5TrendingEvents/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Home;