import React, { useState, useEffect } from "react";
import '../css/userprofile.css';
import functions from "../fn/function";

const UserProfile = (props) => {
    const [activeTab, setActiveTab] = useState('myEvents');
    const [openBio, setOpenBio] = useState(null);
    const [bio, setBio] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [profilePicture, setProfilePicture] = useState("https://via.placeholder.com/150");
    const [confirmPFP, setConfirmPFP] = useState(null);

    useEffect(() => {
        if (!flag) {
            const fetchBio = async () => {
                let obj = {
                    userId: props.username
                }

                const response = await functions.getUserBio(obj);
                const parsedBio = JSON.parse(response.body);
                if(parsedBio.bio === 'Bio not found'){
                    setBio("");
                    setIsLoading(false);
                } else {
                    setBio(parsedBio.bio);
                    setIsLoading(false); 
                }
            }

            fetchBio();
        }
    }, [bio]);

    useEffect(() => {
        const fetchPFP = async () => {
            let obj = {
                userId: props.username
            }

            const response = await functions.getUserProfilePicture(obj);
            if(response.statusCode === 200){
                // console.log(response);
                const parsedPFP = JSON.parse(response.body);
                if(parsedPFP.pfp === 'PFP not found'){
                    setProfilePicture("https://via.placeholder.com/150");
                } else {
                    setProfilePicture(parsedPFP.pfp);
                }
            }
        };

        fetchPFP();
    }, [profilePicture]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const setUpdatedBio = (e) => {
        setBio(e.target.value);
        setFlag(true);
    }

    const saveBio = async () => {
        let obj = {
            bio,
            userId: props.username
        }

        setIsLoading(true);
        const response = await functions.setUserBio(obj);
        if (response.statusCode === 200) {
            setOpenBio(false);
            setIsLoading(false);
            setFlag(false);
            setBio(bio);
        }
    }

    const handleOpenBio = () => {
        setOpenBio(true);
    }

    const handleCloseOpenBio = () => {
        setOpenBio(null);
    }

    const handleCloseConfirmPFP = () => {
        setConfirmPFP(null);
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setConfirmPFP(true);
    }

    const uploadProfilePicture = async () => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1];

                let obj = {
                    userId: props.username,
                    image: base64data
                }

                const response = await functions.updateProfilePicture(obj);
                if (response.statusCode === 200) {
                    const parsedResponse = JSON.parse(response.body);
                    // console.log(parsedResponse);
                    setProfilePicture(parsedResponse.profilePicture);
                    handleCloseConfirmPFP(null);
                } else {
                    console.log(response);
                }
            };
            reader.readAsDataURL(selectedFile);
        } else {
            console.log(selectedFile);
        }
    }

    return (
        <div className="user-profile-container">
            <div className="profile-header">
                <img src={profilePicture} alt="Profile" className="profile-picture" onClick={() => document.getElementById('fileInput').click()} />
                <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                {/* <button onClick={uploadProfilePicture}>Upload Picture</button> */}
                <div className="profile-bio">
                    <h2 className="color-white">{props.username}</h2>
                    <p className="color-white">
                        <span className="bio">
                            {
                                isLoading ?
                                    <div style={{ color: "white" }}>Loading...</div> : <div style={{ color: "white" }}>{bio}</div>
                            }
                            <i className="fa-solid fa-pen up-edit-icon" onClick={handleOpenBio}></i>
                        </span>
                    </p>
                </div>
            </div>
            <div className="profile-tabs">
                <button
                    className={`tab-button ${activeTab === 'myEvents' ? 'blk-bkg' : ''}`}
                    onClick={() => handleTabClick('myEvents')}
                >
                    My Events
                </button>
                <button
                    className={`tab-button ${activeTab === 'followedEvents' ? 'blk-bkg' : ''}`}
                    onClick={() => handleTabClick('followedEvents')}
                >
                    Followed Events
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'myEvents' ? (
                    <div className="my-events">
                        <p>List of my events...</p>
                    </div>
                ) : (
                    <div className="followed-events">
                        <p>List of followed events...</p>
                    </div>
                )}
            </div>

            {openBio && (
                <div className="modal" tabindex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content mc">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Bio</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseOpenBio}></button>
                            </div>
                            <div className="modal-body">
                                <textarea className="form-control" type="text" onChange={setUpdatedBio} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCloseOpenBio}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={saveBio}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {confirmPFP && (
                <div className="modal" tabindex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h4>Please choose "Confirm" to update profile picture.</h4>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={uploadProfilePicture}>Confirm</button>
                                <button type="button" className="btn btn-danger" onClick={handleCloseConfirmPFP}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfile;

