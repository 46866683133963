import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import functions from "../fn/function";
import '../css/calendar.css';

const Calendar = (props) => {
    const location = useLocation();
    const id = props.id;
    const [events, setEvents] = useState([]);
    const [isPopulated, setIsPopulated] = useState(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await functions.getCalendarEvents({ userId: id });
                // console.log(response);
                // console.log(id);
                if (response.status === 200) {
                    const parsedLikedEvents = JSON.parse(response.data.body);
                    setEvents(parsedLikedEvents);
                    setIsPopulated(true);
                } else {
                    console.error('Error1 fetching events:', response);
                }
            } catch (error) {
                console.error('Error2 fetching events:', error);
            }
        };

        fetchEvents();
    }, [props.id]);

    if (isPopulated) {
        return (
            <div className="calendar-container">

                <div className="favorite-events">
                    {events.map((event) => (
                        <div key={event.id} className="calendar-card">
                            {event.images && <img src={event.images[0].url} alt={event.name} className="calendar-image" />}
                            <div className="calendar-details">
                                <h2 className="calendar-name">{event.name}</h2>
                                <p className="calendar-date">{new Date(event.dates.start.dateTime).toLocaleString()}</p>
                                {event.priceRanges && (
                                    <p className="calendar-price">
                                        {`$${event.priceRanges[0].min} - $${event.priceRanges[0].max}`}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return <div className="calendar-loader">Loading...</div>;
    }
}

export default Calendar;
