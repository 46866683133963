import React from 'react';
import '../css/nav.css';
import functions from '../fn/function';
import { useNavigate } from 'react-router-dom';

const Nav = (props) => {

    const navigate = useNavigate();

    const signOut = () => {
      functions.signOut();
      navigate('/');
    }

    return (
        <nav className="navbar navbar-expand home-bottom-nav fixed-bottom">
          <div className="container-fluid">
            <div className="navbar-nav w-100 justify-content-between px-3">
              <a className="nav-item nav-link flex-fill text-center" href="#home" onClick={() => {
                navigate('/home');
              }}>
                <i className="fas fa-home nav-icon"></i>
              </a>
              <a className="nav-item nav-link flex-fill text-center" onClick={() => {
                navigate('/calendar');
              }}>
                <i class="fa-regular fa-calendar-days nav-icon"></i>
              </a>
              <a className="nav-item nav-link flex-fill text-center" href="#notifications" onClick={() => {
                navigate('/addevent');
              }}>
                <i class="fa-solid fa-plus nav-icon"></i>
              </a>
              <a className="nav-item nav-link flex-fill text-center" href="#user" onClick={() => {
                navigate('/my-profile');
              }}>
                <i className="fas fa-user nav-icon"></i>
              </a>
              <a className="nav-item nav-link flex-fill text-center" href="#" onClick={() => { signOut(); }}>
                <i class="fa-sharp fa-solid fa-right-from-bracket nav-icon"></i>
              </a>
            </div>
          </div>
        </nav>
      );
};

export default Nav;
