import React, { useState, useEffect } from "react";
import '../css/header.css';
import { Link } from "react-router-dom";
import functions from "../fn/function";
import { Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [matchedUsers, setMatchedUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const itemsPerPage = 2;

    useEffect(() => {
        if (searchTerm) {
            fetchUsers(searchTerm);
        } else {
            setMatchedUsers([]);
        }
    }, [searchTerm, currentPage]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on new search
    };

    const fetchUsers = async (searchTerm) => {
        let obj = { searchTerm };
        const response = await functions.getExactOrSimilarUsers(obj);
        let parsedResponse = JSON.parse(response.body);
        setMatchedUsers(parsedResponse.matchedEvents);
        setTotalPages(Math.ceil(parsedResponse.matchedEvents.length / itemsPerPage));
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleUsernameClick = (username) => {
        console.log(`Navigate to ${username}'s profile`);
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEvent(null);
    };

    // Paginate matchedUsers
    const paginatedUsers = matchedUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="header-nav fixed-top">
            <img src="https://i.ibb.co/yQpJNW7/buzz1.png" className="img-fluid login-logo-img" alt="BuzzFinder Logo"/>
            <div className="alert header-alert mb-0 p-0" style={{ borderRadius: "0", color:"white" }}>
                Click <Link to="/home">here</Link> to join our mailing list.
            </div>
            <div className="row search-row">
                <form className="d-flex" role="search">
                    <div className="input-group">
                        <input
                            className="form-control home-search"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <span className="input-group-text">
                            <i className="fa fa-search"></i>
                        </span>
                    </div>
                </form>
                {searchTerm && paginatedUsers.length > 0 && (
                    <div className="search-dropdown">
                        <ul>
                            {paginatedUsers.map((event, index) => (
                                <li key={index} className="searched-user-events">
                                    <div className="d-flex justify-content-between align-items-center search-event-box">
                                        <span className="event-name" onClick={() => handleEventClick(event)}>
                                            {event.eventName}
                                        </span>
                                        <i className="fa fa-heart"></i>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center search-user-box">
                                        <div className="d-flex align-items-center">
                                            <img src={event.profilePicture} alt="Profile" className="profile-pic"/>
                                            <span className="username" onClick={() => handleUsernameClick(event.username)}>
                                                {event.username}
                                            </span>
                                        </div>
                                        <i className="fa fa-user-plus"></i>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="pagination">
                            {currentPage > 1 && (
                                <button onClick={() => handlePageChange(currentPage - 1)} className="search-prev btn-lg">Previous</button>
                            )}
                            {currentPage < totalPages && (
                                <button onClick={() => handlePageChange(currentPage + 1)} className="search-next btn-lg">Next</button>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {selectedEvent && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedEvent.eventName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Date:</strong> {selectedEvent.eventDate}</p>
                        <p><strong>Time:</strong> {selectedEvent.eventTime}</p>
                        <p><strong>Location:</strong> {selectedEvent.eventLocation}</p>
                        <p><strong>Description:</strong> {selectedEvent.eventDescription}</p>
                        <p><strong>Cover Charge:</strong> {selectedEvent.coverCharge}</p>
                        <p><strong>Rules:</strong> {selectedEvent.eventRules}</p>
                        {selectedEvent.eventImage && <img src={selectedEvent.eventImage} alt="Event" className="img-fluid"/>}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default Header;





