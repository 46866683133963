import React from 'react';
import '../css/beeloader.css';
import { FaBug } from 'react-icons/fa';

const BeeLoader = () => {
  return (
    <div className="bee-loader-container d-flex justify-content-center align-items-center">
      <div className="bee-loader">
        <img src="https://i.ibb.co/tzwTrh5/kulaidkarlbanner-4.png" className="img-fluid login-logo-img"/>
        <div className="row loader-row">
                <div className="col-12">
                    <div className="card loader-card">
                        <div className="card-body">
                            <h1 className="loader-title">Hang tight!</h1>
                            <h3 className="loader-description">We are loading up the different events in your community.</h3>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  );
};

export default BeeLoader;

