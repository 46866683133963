import axios from "axios";

const base = 'https://1ec4pzzdab.execute-api.us-east-1.amazonaws.com/BuzzFinderStage1';

const functions = {

    // checks if email is valid
    checkUsername: async (obj) => {
        const result = await axios.post(`${base}/username_exist`, obj);
        console.log(result.data);
        return result.data.statusCode;
    },

    // insert user info in database
    insertUserInfo: async (obj) => {
        const result = await axios.post(`${base}/user_intake`, obj);
        return result.data.statusCode;
    },

    // checks if username/pass is valid
    userPassValid: async (obj) => {
        const result = await axios.post(`${base}/signin`, obj);
        return result.data;
    },

    // retrieve user details
    userDetails: async (obj) => {
        const result = await axios.post(`${base}/user_details`, obj);
        return result.data;
    },

    // get events for this month
    getEvents: async () => {
        const result = await axios.get(`${base}/events`);
        const final = JSON.parse(result.data.body);
        return final;
    },
    
    // signs user out
    signOut: () => {
        localStorage.removeItem('id');
    },

    // add user liked event
    addLikedEvent: async (obj) => {
        const result = await axios.post(`${base}/add_liked_event`, obj);
        const final = result.data;
        return final;
    },

    // get all events user has favorited
    getLikedEvents: async (obj) => {
        const result = await axios.post(`${base}/user_liked_events`, obj);
        return result;
    },

    // get calendar events
    getCalendarEvents: async (obj) => {
        // console.log(obj);
        const likedEvents = await functions.getLikedEvents(obj);
        // console.log(likedEvents);
        if(likedEvents.data.statusCode === 200){
            let obj = {
                eventIds: JSON.parse(likedEvents.data.body).events
            }
            const result = await axios.post(`${base}/get_calendar_items`, obj);
            return result;
        }
    },

    // save event under user
    userCreatedEvent: async (obj) => {
        const result = await axios.post(`${base}/create_event`, obj);
        return result.data;
    },

    // get user events
    getUserEvents: async (obj) => {
        const result = await axios.post(`${base}/my_events`, obj);
        return result.data;
    },

    // set user bio
    setUserBio: async (obj) => {
        const result = await axios.post(`${base}/set_user_bio`, obj);
        return result.data;
    },

    // get user bio
    getUserBio: async (obj) => {
        const result = await axios.post(`${base}/get_bio`, obj);
        return result.data;
    },

    // update profile picture
    updateProfilePicture: async (obj) => {
        const result = await axios.post(`${base}/store_pfp`, obj);
        return result.data;
    },

    // get user profile picture
    getUserProfilePicture: async (obj) => {
        const result = await axios.post(`${base}/get_pfp`, obj);
        return result.data;
    },

    // get exact or similar users via search
    getExactOrSimilarUsers: async (obj) => {
        const result = await axios.post(`${base}/get_search_users`, obj);
        return result.data;
    },

    // get random 6 events
    getRandom6Events: async () => {
        const result = await axios.get(`${base}/get_all_events`);
        return result.data;
    },

    // get top 5 trending events
    getTrendingEvents: async () => {
        const result = await axios.get(`${base}/get_top_5`);
        return result.data;
    }

}


export default functions;