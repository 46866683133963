import React, { useEffect, useState } from "react";
import functions from "../fn/function";
import { useNavigate } from "react-router-dom";
import '../css/myevents.css'; 

const MyEvents = (props) => {

    const [myEvents, setMyEvents] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMyEvents = async () => {
            let obj = {
                userId: props.id
            };
            const response = await functions.getUserEvents(obj);
            const result = JSON.parse(response.body);
            setMyEvents([result]);
        }

        fetchMyEvents();
    }, [props.id]);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseImageModal = () => {
        setSelectedImage(null);
    };

    const handleCloseEventModal = () => {
        setSelectedEvent(null);
    };

    const handleViewDetailsClick = (event) => {
        setSelectedEvent(event);
    };

    if (myEvents.length > 0) {
        // console.log(myEvents);
        if (myEvents[0].createdEvents.length === 0) {
            return (
                <div className="card mt-3" style={{margin:"20px"}}>
                    <div className="card-body">
                        <h4>Create your first event</h4>
                        <i className="fa-solid fa-plus nav-icon" onClick={() => {
                            navigate('/addevent');
                        }}></i>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="my-events-title">My Events</h1>
                        </div>
                    </div>

                    <div className="myevents-container">
                    <div className="horizontal-scroll">
                        {myEvents[0].createdEvents.map((event) => {
                            return (
                                <div className="card my-event-card" key={event.eventId}>
                                    <div className="row no-gutters">
                                        <div className="col-md-4 col-sm-4 position-relative">
                                            <img src={event.eventImage} className="img-fluid event-image" onClick={() => handleImageClick(event.eventImage)} />
                                            <div className="icon-overlay d-flex justify-content-center align-items-center">
                                                <i className="fa-solid fa-heart event-icon"></i>
                                                <i className="fa-sharp fa-solid fa-users event-icon"></i>
                                                <i className="fa-solid fa-ticket event-icon"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-sm-8">
                                            <div className="card-body">
                                                <h5 className="card-title">{event.eventName}</h5>
                                                <a href="#" onClick={() => handleViewDetailsClick(event)}>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {selectedImage && (
                        <div className="modal" onClick={handleCloseImageModal} style={{paddingTop:"50px"}}>
                            <span className="close">&times;</span>
                            <img className="modal-content" src={selectedImage} />
                        </div>
                    )}
                    {selectedEvent && (
                        <div className="modal" onClick={handleCloseEventModal} style={{paddingTop:"50px"}}>
                            <div className="modal-content">
                                <span className="close" onClick={handleCloseEventModal}>&times;</span>
                                <h5>{selectedEvent.eventName}</h5>
                                <p><strong>Type:</strong> {selectedEvent.eventType}</p>
                                <p><strong>Date:</strong> {selectedEvent.eventDate}</p>
                                <p><strong>Time:</strong> {selectedEvent.eventTime}</p>
                                <p><strong>Location:</strong> {selectedEvent.eventLocation}</p>
                                <p><strong>Description:</strong> {selectedEvent.eventDescription}</p>
                                <p><strong>Adult Only:</strong> {selectedEvent.isAdultOnly ? 'Yes' : 'No'}</p>
                                <p><strong>Cover Charge:</strong> {selectedEvent.coverCharge}</p>
                                <p><strong>Rules:</strong> {selectedEvent.eventRules}</p>
                            </div>
                        </div>
                    )}
                </div>
                </div>
            )
            // return (
            //     <div className="myevents-container">
            //         <div className="horizontal-scroll">
            //             {myEvents[0].createdEvents.map((event) => {
            //                 return (
            //                     <div className="card my-event-card" key={event.eventId}>
            //                         <div className="row no-gutters">
            //                             <div className="col-md-4 col-sm-4 position-relative">
            //                                 <img src={event.eventImage} className="img-fluid event-image" onClick={() => handleImageClick(event.eventImage)} />
            //                                 <div className="icon-overlay d-flex justify-content-center align-items-center">
            //                                     <i className="fa-solid fa-heart event-icon"></i>
            //                                     <i className="fa-sharp fa-solid fa-users event-icon"></i>
            //                                     <i className="fa-solid fa-ticket event-icon"></i>
            //                                 </div>
            //                             </div>
            //                             <div className="col-md-8 col-sm-8">
            //                                 <div className="card-body">
            //                                     <h5 className="card-title">{event.eventName}</h5>
            //                                     <a href="#" onClick={() => handleViewDetailsClick(event)}>View Details</a>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 )
            //             })}
            //         </div>
            //         {selectedImage && (
            //             <div className="modal" onClick={handleCloseImageModal} style={{paddingTop:"50px"}}>
            //                 <span className="close">&times;</span>
            //                 <img className="modal-content" src={selectedImage} />
            //             </div>
            //         )}
            //         {selectedEvent && (
            //             <div className="modal" onClick={handleCloseEventModal} style={{paddingTop:"50px"}}>
            //                 <div className="modal-content">
            //                     <span className="close" onClick={handleCloseEventModal}>&times;</span>
            //                     <h5>{selectedEvent.eventName}</h5>
            //                     <p><strong>Type:</strong> {selectedEvent.eventType}</p>
            //                     <p><strong>Date:</strong> {selectedEvent.eventDate}</p>
            //                     <p><strong>Time:</strong> {selectedEvent.eventTime}</p>
            //                     <p><strong>Location:</strong> {selectedEvent.eventLocation}</p>
            //                     <p><strong>Description:</strong> {selectedEvent.eventDescription}</p>
            //                     <p><strong>Adult Only:</strong> {selectedEvent.isAdultOnly ? 'Yes' : 'No'}</p>
            //                     <p><strong>Cover Charge:</strong> {selectedEvent.coverCharge}</p>
            //                     <p><strong>Rules:</strong> {selectedEvent.eventRules}</p>
            //                 </div>
            //             </div>
            //         )}
            //     </div>
            // )
        }
    } else {
        return (
            <h1>Loading...</h1>
        )
    }
}

export default MyEvents;

