import React, { useEffect, useState } from "react";
import '../css/register.css';
import { Link, useNavigate } from "react-router-dom";
import functions from "../fn/function";
import { v4 as uuidv4 } from 'uuid';
import BeeLoader from "./beeloader";

const Register = () => {

    const [email, setEmail] = useState("");
    const [emailFlag, setEmailFlag] = useState(false);
    const [username, setUsername] = useState("");
    const [usernameFlag, setUsernameFlag] = useState(false);
    const [pass1, setpass1] = useState("");
    const [pass2, setpass2] = useState("");
    const [id, setId] = useState("");
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkEmail();
    }, [email]);

    const handleRegister = async () => {
        // event.preventDefault();
        setLoader(true);
        setTimeout(() => {
            navigate('/home', {uid: id});
        }, 2000);
    };

    const checkIfPasswordsMatch = () => {
        if(pass1 === pass2){
            return true;
        } else {
            return false;
        }
    }

    if(loader){
        return <BeeLoader/>
    }

    const checkEmail = () => {
        const posOfCharAfterAt = email.indexOf('@') + 1;
        const specialChars =/[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/;
        if (email === "") {

        } else if(!email.includes('@')){
            // console.log('not correctly formatted');
        } else if(!email.includes('.com')){
            // console.log('no domain');
        } else if(specialChars.test(email[posOfCharAfterAt])){
            // console.log('Hmm, something doesn\'t look right. Please try again.');
        } else {
            setEmailFlag(true);
        }
    }

    const checkUsernameEmail = (id) => {
        if(username.length < 5){
            showMessage('username-invisible-text', 'Username too short');
        } else if (username.includes("nigger")){
            showMessage('username-invisible-text', 'Username contains bad word.');
        } else {
            setUsernameFlag(true);
            clearBox(id)
        }
    }

    const clearBox = (id) => {
        document.getElementById(id).innerHTML = "";
        document.getElementById(id).classList.remove('username-invisible-text');
    }

    const showMessage = (id, message) => {
        document.getElementById(id).innerHTML = message;
        document.getElementById(id).classList.add('username-invisible-text');
    }

    const togglePass1 = (e1, e2) => {
        const element = document.getElementById(e1);
        const element2 = document.getElementById(e2);
        element.type === 'password' ? element.type = 'text': element.type = 'password';
        if(element.type === 'password'){
            element2.className = '';
            element2.classList.add('fa','fa-eye-slash');
        } else {
            element2.className = '';
            element2.classList.add('fa-regular', 'fa-eye');
        }
    }


    const checkUsername = async () => {
        let obj = {username, email};
        const result = await functions.checkUsername(obj);
        if(result === 200 && emailFlag && usernameFlag && checkIfPasswordsMatch()){
            let obj = {username, email, password: pass1, uuid: uuidv4()};
            const response = await functions.insertUserInfo(obj);
            localStorage.setItem('id', obj.uuid);
            setId(obj.uuid);
            handleRegister();
        } else {
            if(result !== 200){
                alert('username/email is already taken, or not filled in. please try again.');
            } else if (!emailFlag || !checkIfPasswordsMatch()){
                alert('something is wrong with your email or password. please try again.');
            } else if(!usernameFlag){
                console.log(usernameFlag);
                console.log('the program does not recognize the username.')
            }else {
                alert('something happened. please contact support.');
            }
        }
    }

    return (
        <div className="login-container">
            <img src="https://i.ibb.co/tzwTrh5/kulaidkarlbanner-4.png" className="img-fluid login-logo-img"/>
            <div className="row p-5">
                <div className="col-12">
                    <div className="card login-card">
                        <div className="card-body">
                            <h1 className="login-title">Join the BuzzFinder Community!</h1>
                            <h3 className="login-description">Check in to the best spots and see what's buzzing around you.</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-auto mt-1">
                <div className="col-12 mb-3">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text ipt" id="inputGroup-sizing-lg email">Email</span>
                        <input onChange={(e) => { setEmail(e.target.value) }} type="text" class="form-control register-email" id="register-email" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="Email"/>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <h7 id="username-invisible-text" className=""></h7>
                    <div class="input-group input-group-lg">
                        <span class="input-group-text ipt" id="inputGroup-sizing-lg username">Username</span>
                        <input onChange={(e) => { 
                            setUsername(e.target.value); 
                        }} id="username" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="Username"/>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text ipt" id="inputGroup-sizing-lg password-title">Password</span>
                        <input onChange={(e) => {setpass1(e.target.value)}} onClick={(e) => {
                            checkUsernameEmail('username-invisible-text');
                        }} id="password" type="password" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="Password"/>
                        <span className="input-group-text"><i onClick={() => {
                            togglePass1("password", "togglePassword");
                        }} className="fa fa-eye-slash" id="togglePassword"></i></span>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div class="input-group input-group-lg">
                        <span class="input-group-text ipt" id="inputGroup-sizing-lg">Confirm Password</span>
                        <input onChange={(e) => {setpass2(e.target.value)}} id="password2" type="password" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" placeholder="Confirm Password"/>
                        <span className="input-group-text"><i onClick={() => {
                            togglePass1("password2", "togglePassword2");
                        }} className="fa fa-eye-slash" id="togglePassword2"></i></span>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <Link to='/' className="create-account">Have an account? Log In!</Link>
                </div>
                <div className="col-12">
                    <div className="btn btn-lg login-btn register-login-btn" onClick={() =>{
                        checkUsername();
                    }}>Log In</div>
                </div>
            </div>
        </div>
    )
}

export default Register;