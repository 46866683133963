import React, { useState } from "react";
import '../css/login.css';
import { Link, useNavigate } from "react-router-dom";
import functions from "../fn/function";
import BeeLoader from "./beeloader";

const Login = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPass] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const checkCredentials = async () => {
        setIsButtonDisabled(true);
        let obj = { username, password };
        let result = await functions.userPassValid(obj);
        // console.log(result);

        if (result.statusCode === 200) {
            setLoading(true);
            setTimeout(() => {
                props.addToState(result.body);
                navigate('/home');
            }, 2000);
        } else {
            alert('Invalid username/password combination. Please try again.');
            setIsButtonDisabled(false); // Re-enable the button if credentials are invalid
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    if (loading) {
        return <BeeLoader />
    }

    return (
        <div className="login-container">
            <img src="https://i.ibb.co/tzwTrh5/kulaidkarlbanner-4.png" className="img-fluid login-logo-img" alt="Logo" />

            <div className="row p-5">
                <div className="col-12">
                    <div className="card login-card">
                        <div className="card-body">
                            <h1 className="login-title">Join the BuzzFinder Community!</h1>
                            <h3 className="login-description">Check in to the best spots and see what's buzzing around you.</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-auto mt-1">
                <div className="col-12 mb-3">
                    <div className="input-group input-group-lg">
                        <span className="input-group-text ipt" id="inputGroup-sizing-lg">username</span>
                        <input
                            onChange={(e) => setUsername(e.target.value)}
                            id="login-username"
                            type="text"
                            className="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"
                            autoComplete="new-username"
                        />
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <div className="input-group input-group-lg">
                        <span className="input-group-text ipt" id="inputGroup-sizing-lg">password</span>
                        <input
                            onChange={(e) => setPass(e.target.value)}
                            id="password3"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"
                            autoComplete="new-password"
                        />
                        <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer', zIndex: 10 }}>
                            <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"} id="togglePassword3"></i>
                        </span>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <Link to='/register' className="create-account">Don't have an account? Sign up!</Link>
                </div>
                <div className="col-12">
                    <button 
                        onClick={checkCredentials} 
                        className={`btn btn-lg login-btn ${isButtonDisabled ? 'disabled' : ''}`} 
                        id="login-btn"
                        disabled={isButtonDisabled}
                    >
                        Log In
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login;



