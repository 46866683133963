// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/eventlist.css';
// import functions from '../fn/function';
// import { Modal, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const base = 'https://your-api-gateway-url'; 

// const EventList = () => {
//     const [events, setEvents] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [selectedEvent, setSelectedEvent] = useState(null);

//     useEffect(() => {
//         const fetchEvents = async () => {
//             try {
//                 const response = await functions.getRandom6Events();
//                 const final = JSON.parse(response.body);
//                 // console.log(final);
//                 setEvents(final.selectedEvents);
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchEvents();
//     }, []);

//     const handleShowModal = (event) => {
//         setSelectedEvent(event);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setSelectedEvent(null);
//         setShowModal(false);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="container event-list-container">
//             <div className="row row-cols-2 row-cols-md-2 g-4">
//                 {events.map((event, index) => (
//                     <div key={index} className="col">
//                         <div className="card event-list-card h-100">
//                             <div className="card-body">
//                                 <h3 className="card-title">{event.eventName}</h3>
//                                 {/* {event.eventImage && <img src={event.eventImage} alt={event.eventName} className="img-fluid event-list-image"/>} */}
//                                 <Button variant="primary" onClick={() => handleShowModal(event)} className="mt-3">View Details</Button>
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>

//             {selectedEvent && (
//                 <Modal show={showModal} onHide={handleCloseModal}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>{selectedEvent.eventName}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <p><strong>Date:</strong> {selectedEvent.eventDate}</p>
//                         <p><strong>Time:</strong> {selectedEvent.eventTime}</p>
//                         <p><strong>Location:</strong> {selectedEvent.eventLocation}</p>
//                         <p><strong>Description:</strong> {selectedEvent.eventDescription}</p>
//                         <p><strong>Cover Charge:</strong> {selectedEvent.coverCharge}</p>
//                         <p><strong>Rules:</strong> {selectedEvent.eventRules}</p>
//                         {selectedEvent.eventImage && <img src={selectedEvent.eventImage} alt={selectedEvent.eventName} className="img-fluid"/>}
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
//                     </Modal.Footer>
//                 </Modal>
//             )}
//         </div>
//     );
// };

// export default EventList;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/eventlist.css';
import functions from '../fn/function';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const base = 'https://your-api-gateway-url'; 

const EventList = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await functions.getRandom6Events();
                const final = JSON.parse(response.body);
                setEvents(final.selectedEvents);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    const handleShowModal = (event) => {
        setSelectedEvent(event);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setSelectedEvent(null);
        setShowModal(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='el-container'>
            <div className='row'>
                <div className='col-12'>
                    <h1 className='event-list-title'>Events Near You</h1>
                </div>
            </div>

            <div className="container event-list-container">
            <div className="row row-cols-2 row-cols-md-2 g-4">
                {events.map((event, index) => (
                    <div key={index} className="col">
                        <div className="card event-list-card h-100">
                            {event.eventImage && (
                                <div className="event-list-image-container">
                                    <img src={event.eventImage} alt={event.eventName} className="event-list-image"/>
                                </div>
                            )}
                            <div className="card-body">
                                <h3 className="card-title">{event.eventName}</h3>
                                <Button variant="" onClick={() => handleShowModal(event)} className="mt-3 event-list-btn">View Details</Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {selectedEvent && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedEvent.eventName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Date:</strong> {selectedEvent.eventDate}</p>
                        <p><strong>Time:</strong> {selectedEvent.eventTime}</p>
                        <p><strong>Location:</strong> {selectedEvent.eventLocation}</p>
                        <p><strong>Description:</strong> {selectedEvent.eventDescription}</p>
                        <p><strong>Cover Charge:</strong> {selectedEvent.coverCharge}</p>
                        <p><strong>Rules:</strong> {selectedEvent.eventRules}</p>
                        {selectedEvent.eventImage && <img src={selectedEvent.eventImage} alt={selectedEvent.eventName} className="img-fluid"/>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
        </div>
    );
};

export default EventList;

