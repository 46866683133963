import React, { useState, useEffect } from 'react';
import '../css/rapevents.css';
import functions from '../fn/function';

const TicketmasterEvents = (props) => {
    const [events, setEvents] = useState([]);
    const [isPopulated, setIsPopulated] = useState(false);
    const [likedEvents, setLikedEvents] = useState([]);



    useEffect(() => {
        const fetchLikedEvents = async () => {
            try {
                const response = await functions.getLikedEvents({ userId: props.id });
                // console.log(response);
                if (response.data.statusCode === 200) {
                    const parsedLikedEvents = JSON.parse(response.data.body);
                    setLikedEvents(parsedLikedEvents.events);
                } else {
                    console.error('Error fetching liked events:', response);
                }
            } catch (error) {
                console.error('Error fetching liked events:', error);
            }
        };

        fetchLikedEvents();
    }, [props.id]);

    useEffect(() => {
        if (props.rapevents._embedded) {
            setEvents(props.rapevents._embedded.events);
            setIsPopulated(true);
        } else {
            setIsPopulated(false);
        }
    }, [props.rapevents]);

    const addLikeEvent = async (eventId) => {
        let obj = {
            eventId,
            userId: props.id,
            username: props.name
        }
        const response = await functions.addLikedEvent(obj);
        const statusCode = response.statusCode;
        const element = document.getElementById(`${eventId}`);
        if(statusCode === 200){
            element.style.color = 'red';
            const parsedLikedEvents = JSON.parse(response.body);
            setLikedEvents(parsedLikedEvents.events);
        } else {
            // console.log(response);
            alert('something went wrong. please try again.');
        }
    }

    const isEventLiked = (eventId) => {
        return likedEvents.includes(eventId);
    };

    const getLikedEvents = async (userId) => {
        let obj = {
            userId
        }
        const response = await functions.getLikedEvents(obj);
        return response;
    }

    if (isPopulated) {
        return (
            <div className=''>
                <div className='row text-left'>
                    <div className='col-12'>
                        <h1 className='mb-0 mt-3 tm-rap-rnb-title'>
                            Rap/RnB Concerts
                        </h1>
                    </div>
                </div>

                <div className="events-container">
                {events.map((event) => (
                    <div key={event.id} className="event-card">
                        {event.images && <img src={event.images[0].url} alt={event.name} className="event-image" />}
                        <div className="event-details">
                            <h2 className="event-name">{event.name}</h2>
                            <p className="event-date">{new Date(event.dates.start.dateTime).toLocaleString()}</p>
                            {event.priceRanges && (
                                <p className="event-price">
                                    {`$${event.priceRanges[0].min} - $${event.priceRanges[0].max}`}
                                </p>
                            )}
                            <div className='w-100 justify-content-between px-3'>
                                <div className="buy-tickets-button">
                                    <i id={event.id} className={`fa-solid fa-heart event-icon ${isEventLiked(event.id) ? 'liked' : ''}`} onClick={() => {
                                        addLikeEvent(event.id);
                                    }}></i>
                                    <i className="fa-sharp fa-solid fa-users event-icon"></i>
                                    <a href={event.url}>
                                        <i className="fa-solid fa-ticket event-icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        );
    } else {
        return <div>Loading...</div>;
    }
};

export default TicketmasterEvents;

