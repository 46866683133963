import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/addevent.css';
import functions from '../fn/function';
import { Navigate, useNavigate } from 'react-router-dom';

const AddEvent = (props) => {
    const [eventName, setEventName] = useState('');
    const [eventType, setEventType] = useState('House Party');
    const [eventDate, setEventDate] = useState('');
    const [eventTime, setEventTime] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventImage, setEventImage] = useState(null);
    const [isAdultOnly, setIsAdultOnly] = useState(false);
    const [hasCoverCharge, setHasCoverCharge] = useState(false);
    const [coverChargeAmount, setCoverChargeAmount] = useState('');
    const [eventRules, setEventRules] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setEventImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newEvent = createEventObject();
        if (newEvent) {
            // console.log('Event Created:', newEvent);
            let response = await functions.userCreatedEvent(newEvent);
            if(response.statusCode === 200){
                resetForm();
                navigate('/home');
            } else {
                console.log(response);
            }
        }
    };

    const createEventObject = () => {
        if (
            !eventName ||
            !eventType ||
            !eventDate ||
            !eventTime ||
            !eventLocation ||
            !eventDescription ||
            (hasCoverCharge && !coverChargeAmount)
        ) {
            setErrorMessage('Please fill in all required fields.');
            return null;
        }

        setErrorMessage('');

        let obj = {
            body: {
                eventName,
                eventType,
                eventDate,
                eventTime,
                eventLocation,
                eventDescription,
                eventImage,
                isAdultOnly: isAdultOnly ? `${isAdultOnly}` : 'Open to everyone',
                coverCharge: hasCoverCharge ? `$${coverChargeAmount}` : 'Free',
                eventRules,
                id: props.id
            }
        }
        return obj;
    };

    const resetForm = () => {
        setEventName('');
        setEventType('House Party');
        setEventDate('');
        setEventTime('');
        setEventLocation('');
        setEventDescription('');
        setEventImage(null);
        setIsAdultOnly(false);
        setHasCoverCharge(false);
        setCoverChargeAmount('');
        setEventRules('');
    };

    return (
        <div className="container addevent-container">
            <div className="card">
                <div className="card-body">
                    <h2 className="text-center mb-4">Create a New Event</h2>
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                        <div className="mb-3">
                            <label htmlFor="eventName" className="form-label">Event Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="eventName"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide an event name.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventType" className="form-label">Event Type</label>
                            <select
                                className="form-select"
                                id="eventType"
                                value={eventType}
                                onChange={(e) => setEventType(e.target.value)}
                                required
                            >
                                <option value="House Party">House Party</option>
                                <option value="Club Event">Club Event</option>
                                <option value="Festival">Festival</option>
                                <option value="Public Meet">Public Meet</option>
                            </select>
                            <div className="invalid-feedback">
                                Please select an event type.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventDate" className="form-label">Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="eventDate"
                                value={eventDate}
                                onChange={(e) => setEventDate(e.target.value)}
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide a date.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventTime" className="form-label">Time</label>
                            <input
                                type="time"
                                className="form-control"
                                id="eventTime"
                                value={eventTime}
                                onChange={(e) => setEventTime(e.target.value)}
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide a time.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventLocation" className="form-label">Location</label>
                            <input
                                type="text"
                                className="form-control"
                                id="eventLocation"
                                value={eventLocation}
                                onChange={(e) => setEventLocation(e.target.value)}
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide a location.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventDescription" className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                id="eventDescription"
                                value={eventDescription}
                                onChange={(e) => setEventDescription(e.target.value)}
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide a description.
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="eventImage" className="form-label">Upload Image (optional)</label>
                            <input
                                type="file"
                                className="form-control"
                                id="eventImage"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isAdultOnly"
                                checked={isAdultOnly}
                                onChange={(e) => setIsAdultOnly(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="isAdultOnly">18+ Only</label>
                        </div>
                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="hasCoverCharge"
                                checked={hasCoverCharge}
                                onChange={(e) => setHasCoverCharge(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="hasCoverCharge">Cover Charge</label>
                        </div>
                        {hasCoverCharge && (
                            <div className="mb-3">
                                <label htmlFor="coverChargeAmount" className="form-label">Cover Charge Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="coverChargeAmount"
                                    value={coverChargeAmount}
                                    onChange={(e) => setCoverChargeAmount(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please provide the cover charge amount.
                                </div>
                            </div>
                        )}
                        <div className="mb-3">
                            <label htmlFor="eventRules" className="form-label">Rules (optional)</label>
                            <textarea
                                className="form-control"
                                id="eventRules"
                                value={eventRules}
                                onChange={(e) => setEventRules(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Create Event</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddEvent;
